<template>
  <div>
    <div
      class="heading2 q-mb-lg"
      v-text="content.titel"
      v-if="content.titel"
    />
    <div
      v-if="!isMobile"
      class="col-12 row q-col-gutter-md"
    >
      <div
        class="col-xs-12 col-sm-4 imageContainer"
        v-for="img in imageList"
        :key="img"
      >
        <Image
          :media-id="img"
          :sizes="{ xs: 600, lg: 900 }"
          border-radius="16px"
          loading="lazy"
        />
      </div>
    </div>

    <ContentblokkenFreeModeSwiper
      v-else-if="imageList"
      :images="imageList"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<GalerijContentBlock>,
    required: true,
  },
});

const imageList: number[] = (await fetchData('/api/folder/' + props.content.folder)) as number[];
const isMobile = getIsMobile();
</script>

<style lang="scss" scoped>
.imageContainer {
  height: 300px;
  overflow: hidden;
  gap: 10px;

  .Image {
    border-radius: 16px;
    height: 100%;
  }
}
</style>
