<template>
  <div class="HeaderContentBlock">
    <iframe
      v-if="content.youtubeLink"
      width="100%"
      height="800px"
      :src="youtubeUrl"
      title="YouTube video player"
      frameborder="0"
      allowfullscreen
    />

    <Image
      v-else-if="content.afbeelding?.id"
      :media-object="content.afbeelding"
      border-radius="0"
      :sizes="{ xs: 500, md: 1000, lg: 2600, xl: 3000 }"
    >
      <div class="content">
        <div
          v-text="content.titel"
          v-if="content.titel"
          class="heading1"
        />
        <p
          v-html="content.subtitel"
          class="heading4 q-mt-md raw"
        />

        <div class="row q-gutter-md q-mt-md">
          <ContentblokkenButton
            :name="content.knop1.name"
            :link="content.knop1.link"
            :target="content.knop1.target"
          />

          <a
            :href="content.knop2.link"
            :target="content.knop2.target"
          >
            <q-btn
              v-if="content.knop2.name"
              color="primary-dark"
              outline
            >
              {{ content.knop2.name }}
            </q-btn>
          </a>
        </div>
      </div>
    </Image>

    <div
      class="content nonImage"
      v-else
    >
      <div
        v-text="content.titel"
        v-if="content.titel"
        class="heading1"
      />
      <p
        v-html="content.subtitel"
        class="heading4 q-mt-md raw"
      />

      <div class="row q-gutter-md q-mt-md">
        <ContentblokkenButton
          :name="content.knop1.name"
          :link="content.knop1.link"
          :target="content.knop1.target"
        />

        <a
          :href="content.knop2.link"
          :target="content.knop2.target"
        >
          <q-btn
            v-if="content.knop2.name"
            color="primary-dark"
            outline
          >
            {{ content.knop2.name }}
          </q-btn>
        </a>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<HeaderContentBlock>,
    required: true,
  },
});

let youtubeParams = '?autoplay=1&mute=1';
if (props.content.tijdVanInSeconden) youtubeParams += `&start=${props.content.tijdVanInSeconden}`;
if (props.content.tijdTotInSeconden) youtubeParams += `&end=${props.content.tijdTotInSeconden}`;

const youtubeUrl = computed(() => props.content.youtubeLink.replace('/watch?v=', '/embed/') + youtubeParams);
const backgroundImage = computed(() => (props.content.afbeelding?.id ? getImageUrl(props.content.afbeelding.id, 1400, 560) : null));
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.HeaderContentBlock {
  width: 100%;
  max-height: 560px;
  position: relative;
  padding: 0 !important;
  display: contents;

  &.hasImage {
    background-size: cover;
    color: white;
  }

  :deep(.Image) {
    width: 100%;
    border-radius: 0;
    height: 560px;
  }
}

.content {
  position: absolute;
  bottom: 56px;
  left: 56px;
  border-radius: 8px;
  background: none;
  color: white;

  &.nonImage {
    padding: 48px;
    position: relative;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: $screen-xs) {
  .content {
    bottom: 32px;
    left: 8px;
  }
}
</style>
