<template>
  <div>
    <div
      class="heading2 q-mb-lg heading"
      v-text="content.titel"
      v-if="content.titel"
    />

    <ContentblokkenFreeModeSwiper
      v-if="imageList"
      :images="imageList"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<CarrouselContentBlock>,
    required: true,
  },
});

const imageList: number[] = ref([]);
const res: number[] = (await fetchData('/api/media/folder/' + props.content.folder)) as number[];
if (res.length) {
  imageList.value = res.slice(0, 20);
}
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.heading {
  max-width: 1200px;
  margin: 0 auto 32px auto;
}
</style>
