<template>
  <div class="HeaderTextAndImageContentBlock column flex">
    <Image
      class="backgroundImage"
      :media-object="content.headerAfbeelding"
      :sizes="{ xs: 600, lg: 1200, xl: 1500 }"
      border-radius="0"
    />


    <div class="row justify-center">
      <div
        class="content row q-pa-xl"
        :class="reverseClass"
      >
        <div class="col-xs-12 col-sm-7 tekst">
          <ContentblokkenHeadingTypeComponent
            class="q-mb-sm"
            :title="content.titel"
            v-if="content.titel"
            :heading-type="content.titelOpmaak"
          />

          <div
            v-html="content.tekst"
            class="q-mt-lg light raw"
          />
          <ContentblokkenButton
            :name="content.knop.name"
            :link="content.knop.link"
            :target="content.knop.target"
          />
        </div>
        <div class="col-xs-12 offset-sm-1 col-sm-4 imageContainer">
          <Image
            :media-object="content.afbeelding"
            :sizes="{ xs: 400, lg: 600 }"
            loading="lazy"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<HeaderTekstEnAfbeeldingContentBlock>,
    required: true,
  },
});

const reverseClass = props.content.uitlijningTekst === 'false' ? 'reverse' : 'non-reverse';
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.HeaderTextAndImageContentBlock {
  position: relative;
}

.content {
  background: $background;
  top: 50%;
  max-width: 1350px;
  border-radius: 16px;
  margin-top: -250px;
  z-index: 2;
}

:deep(.backgroundImage) {
  height: 500px;
  // object-fit: cover;

  img {
    border-radius: 0;
  }
}

.Image {
  border-radius: 8px;
  float: right;
}

.reverse {
  .tekst {
    padding-left: 24px;
  }
}

.non-reverse {
  .tekst {
    padding-right: 24px;
  }
}

.imageContainer {
  border-radius: 8px;
  background-repeat: no-repeat;
}

@media (max-width: $screen-xs) {
  .content {
    position: relative;
    margin-top: 0;
  }

  .backgroundImage {
    height: 300px;
    margin-top: 10px;
  }

  // .Image {
  // }

  .heading2 {
    font-size: 24px;
  }

  .reverse {
    .tekst {
      padding-left: 0;
    }
  }
}
</style>
