import useAuthStore from '~/store/auth';
import useApiStore from '~/store/api';

export const fetchData = async (endpoint: string, config = {}, showErrorToast = true, getError = false) => {
  const authStore = useAuthStore();

  const runTimeConfig = useRuntimeConfig();
  // const BASE_URL = process.server ? runTimeConfig.public.serverBase : runTimeConfig.public.apiBase;
  const BASE_URL = runTimeConfig.public.apiBase;
  const url = BASE_URL + endpoint;

  const { data, error } = await useFetch(url, {
    headers: {
      Authorization: `bearer ${authStore.token}`,
    },
    watch: false,
    ...config,
  });

  if (error.value) {
    if (error.value.data?.detail && showErrorToast && process.client) {
      setToast({
        type: 'negative',
        message: error.value.data?.detail,
      });
    }

    if (runTimeConfig.public.logRequests) {
      console.log({
        url,
        config,
        data: data.value,
        error: error.value,
      });
    }

    return getError ? { error: error.value.data } : false;
  }

  if (runTimeConfig.public.logRequests) {
    console.log({
      url,
      config,
      data: data.value,
      error: error.value,
    });
  }

  return data.value;
};

// FetchData is only for SSR. Sometimes you want a non-SSR request for example when the app hasn't finished loading config
export const useClientFetch = async (endpoint: string, config = {}) => {
  const authStore = useAuthStore();

  const runTimeConfig = useRuntimeConfig();
  const BASE_URL = runTimeConfig.public.apiBase;
  const url = BASE_URL + endpoint;
  // config.server = false
  // config.lazy = true

  const data = await $fetch(url, {
    headers: {
      Authorization: `bearer ${authStore.token}`,
    },
    ...config,
  });

  if (runTimeConfig.public.logRequests) {
    console.log({
      url,
      config,
      data,
    });
  }

  return data;
};

export async function simpleFetch(endpoint: string, config = {}, showErrorToast = true, getError = false) {
  const authStore = useAuthStore();

  const runTimeConfig = useRuntimeConfig();
  // const BASE_URL = process.server ? runTimeConfig.public.serverBase : runTimeConfig.public.apiBase;
  const BASE_URL = runTimeConfig.public.apiBase;
  const url = BASE_URL + endpoint;

  const response = await useFetch(url, {
    headers: {
      Authorization: `bearer ${authStore.token}`,
    },
    watch: false,
    ...config,
  });

  if (response?.error.value) {
    if (response.error.value.data?.detail && showErrorToast && process.client) {
      setToast({
        type: 'negative',
        message: response.error.value.data?.detail,
      });
    }
  }

  return response;
}

// Maybe this should be the new default... TEST IT
export async function storeFetch(endpoint: string, config = {}) {
  const apiStore = useApiStore();
  const authStore = useAuthStore();
  const baseUrl = apiStore.apiBase;
  const url = baseUrl + endpoint;

  const data = await $fetch(url, {
    headers: {
      Authorization: `bearer ${authStore.token}`,
    },
    ...config,
  });

  return data;
}
