export function getImageUrl(id: number, width = 1000, height = 800, webp = false) {
  if (!id) return null;
  const config = useRuntimeConfig();
  const baseUrl = `${config.public.apiBase}/api/media/${id}?width=${width}&height=${height}`;
  return webp ? `${baseUrl}.webp` : baseUrl;
}

// export async function getImage(id: number, width = 1000, height = 800) {
//   if (!id) return null;
//   try {
//     const response = (await fetchData('/media/file/' + id + '?width=' + width + '&height=' + height)) as ElocusMediaResponse;
//     return response;
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// }

// temp
export async function getImage(id: number, width = 1000, height = 800) {
  if (!id) return null;
  try {
    let w = width;
    let h = height;
    if (width.xs) {
      w = width.xs;
      h = height.xs;
    }
    const response = (await fetchData('/api/media/file/' + id + '?width=' + w + '&height=' + h)) as ElocusMediaResponse;

    return response;
  } catch (e) {
    console.error(e);
    return null;
  }
}
