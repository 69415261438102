<template>
  <div>
    <div
      class="heading2 q-mb-lg heading"
      v-text="content.titel"
      v-if="content.titel"
    />

    <a :href="link">
      <Image
          v-if="content.afbeelding"
        :media-id="content.afbeelding"
        :alt="content.afbeeldingAltTekst"
        :sizes="sizes"
        :class="{ pointer: content.link, small: content.weergave === 'small' }"
        loading="lazy"
      />

    </a>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<AfbeeldingContentBlockType>,
    required: true,
  },
});

const link = computed(() => (props.content.link.length > 1 ? props.content.link : undefined));
const isSmall = computed(() => props.content.weergave === 'small');

const sizes = computed(() => {
  if (isSmall.value) {
    return { xs: 600, lg: 600 };
  }
  return { xs: 600, lg: 1200 };
});
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.Image {
  width: 100%;
  border-radius: 8px;

  &.small {
    max-width: 50%;
  }
}

@media (max-width: $screen-xs) {
  .Image {
    width: 100%;
    max-width: 100%;

    &.small {
      max-width: 100%;
    }
  }
}
</style>
